<div class="bar-wrapper">
    <tui-root>
        <ng-container *ngIf="isBackendAvailable">
            <div class="wrapper" *noFrame>
                <app-header></app-header>
                <main class="main-content">
                    <router-outlet></router-outlet>
                </main>
                <!-- <app-rubic-footer class="footer"></app-rubic-footer> -->
                <paw-footer></paw-footer>
            </div>

            <div class="iframe-wrapper" *onlyFrame>
                <div class="iframe-content">
                    <app-header></app-header>
                    <main class="main-content">
                        <router-outlet></router-outlet>
                    </main>
                </div>
            </div>
        </ng-container>

        <ng-container ngProjectAs="tuiOverDialogs">
            <app-maintenance *ngIf="isBackendAvailable === false"></app-maintenance>
        </ng-container>

        <ng-container ngProjectAs="tuiOverNotifications">
            <!-- Content over notifications -->
        </ng-container>

        <ng-container ngProjectAs="tuiOverPortals">
            <!-- Content over dropdowns -->
        </ng-container>

        <ng-container ngProjectAs="tuiOverHints">
            <!-- Content over hints -->
        </ng-container>
    </tui-root>
</div>