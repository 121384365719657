export enum ROUTE_PATH {
  NONE = '',
  LIMIT_ORDER = 'limit-order',
  HISTORY = 'history',
  BYT_CRYPTO = 'buy-crypto',
  ABOUT = 'about',
  FAQ = 'faq',
  REVOKE_APPROVAL = 'revoke-approval',
  REST = '**'
}

export enum EXTERNAL_LINKS {
  LANDING = 'https://rubic.exchange',
  LANDING_STAKING = 'https://rubic.exchange/staking',
  LANDING_SETUP_WIDGET = 'https://rubic.exchange/widget',
  LANDING_TEAM = 'https://rubic.exchange/team',
  LANDING_SDK = 'https://tools.rubic.exchange/sdk',
  SDK = 'https://docs.rubic.finance/rubic-sdk/sdk-integrations?_ga=2.66724845.2127003169.1647858642-998064740.1646059970',
  AIRDROP = 'https://claim.rubic.exchange',
  GO_PLUS_LABS = 'https://gopluslabs.io/token-security'
}
