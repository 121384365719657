<div class="maintenance">
    <img class="maintenance__logo" src="assets/images/paw-logo.png?_=2" width="132" height="39" alt="rubic logo" />
    <div class="maintenance__content">
        <div class="maintenance__title">
            {{ 'maintenance.title' | translate }}
        </div>
        <div class="maintenance__description">
            {{ 'maintenance.text' | translate }}
        </div>
    </div>
</div>