<div class="footer">
    <div class="footer-wrapper">
        <div class="bullets">
            <div class="opt color"><a href="https://pawecosystem.com/">WEBSITE</a></div>
            <div class="opt color"><a href="https://pawecosystem.com/buy.html">HOW TO BUY</a></div>
            <div class="opt color"><a href="https://dexscreener.com/ethereum/0x0dba3dfee43d9b6450c716c58fdae8d3be37fdc9">CHART</a></div>
            <div class="opt color"><a href="https://stake.pawecosystem.com">STAKE</a></div>
        </div>
        <div class="bullets">
            <div class="opt">
                <div class="btnlong"><img src="../../../assets/images/paw.png" width="25px" style="vertical-align: middle;">
                    <span class="btnlong-text" *ngIf="this.priceUsd == undefined"><img src="../../../assets/images/loading.gif" width="25px" style="vertical-align: middle;"></span>
                    <span class="btnlong-text" *ngIf="this.priceUsd != undefined">${{this.priceUsd}}</span>
                </div>
            </div>
            <div class="opt">
                <div class="btnlong" style="padding-bottom: 7.5px;"><span class="btnlong-bullet">TVL</span>
                    <span class="btnlong-text" *ngIf="tvl == undefined"><img src="../../../assets/images/loading.gif" width="25px" style="vertical-align: middle;"></span>
                    <span class="btnlong-text" *ngIf="tvl != undefined">${{tvl}}</span>
                </div>
            </div>
        </div>
        <div class="bullets">
            <a href="https://twitter.com/pawshibarium" target="_blank" class="opt icon"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path
                        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm2.775 7.001c-1.615 0-2.925 1.31-2.925 2.925 0 .085-.007.242 0 .325-2.092 0-3.619-1.091-4.763-2.494-.153-.189-.315-.09-.349.044-.076.303-.102.809-.102 1.171 0 .911.712 1.805 1.82 2.36-.204.053-.429.09-.663.09a1.93 1.93 0 0 1-.87-.218c-.103-.054-.324-.039-.259.224.263 1.052 1.464 1.791 2.538 2.007-.244.144-.764.114-1.003.114a7.18 7.18 0 0 1-.595-.046c-.181-.022-.46.025-.227.378.501.759 1.635 1.235 2.61 1.253-.898.705-2.367.962-3.775.962-.285-.006-.27.318-.041.438 1.038.545 2.738.867 3.953.867 4.83 0 7.575-3.681 7.575-7.151l-.003-.291c0-.012.003-.023.003-.034 0-.018-.005-.034-.005-.052l-.006-.214c.383-.276.969-.756 1.266-1.123.101-.125.019-.276-.118-.229-.353.123-.963.361-1.346.406.765-.506 1.143-.947 1.468-1.437.111-.167-.028-.337-.198-.256-.53.25-1.431.72-1.884.809-.018.005-.032.01-.049.015-.528-.521-1.253-.844-2.054-.844z"
                        fill="currentColor" fill-rule="evenodd"></path>
                </svg></a>
            <a href="https://t.me/Paw_Community_Owned" target="_blank" class="opt icon"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path
                        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm4.897 5.689c-.188.008-.36.062-.512.122-.14.055-.642.266-1.442.602l-9.09 3.823c-.045.019-.226.074-.427.227s-.424.486-.424.851a1.13 1.13 0 0 0 .324.764c.177.171.357.251.507.311l2.384.952.805 2.435c.091.292.181.482.306.641.062.079.137.151.229.208.035.022.074.039.113.055l.001.001c.011.005.021.011.033.015.019.006.032.006.057.011.089.027.178.046.26.046.275 0 .467-.116.539-.168l.031-.024.014-.01 1.526-1.291L14 16.988c.033.047.301.41.92.41.369 0 .663-.182.849-.373a1.23 1.23 0 0 0 .355-.654v-.004l1.855-9.328a1.39 1.39 0 0 0-.022-.586c-.066-.245-.243-.484-.457-.609s-.416-.163-.604-.155zm-.256 1.423l-1.771 8.908-1.856-1.715a1.31 1.31 0 0 0-1.721-.038l-.947.801.505-1.975 3.696-3.734c.169-.164.204-.222.204-.279 0-.076-.039-.131-.129-.131-.081 0-.192.078-.25.114-.431.269-1.677.988-2.951 1.719l-.509.292-2.03 1.157c-.058-.035-.115-.071-.179-.096l-2.055-.823 9.995-4.2z"
                        fill="currentColor" fill-rule="evenodd"></path>
                </svg></a>
            <a href="https://medium.com/@0xpawswap" target="_blank" class="opt icon"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path
                        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM9.801 7H6.245v.184l.923 1.107c.037.044.057.099.057.157v6.018c0 .09-.031.176-.087.246L6 16.122v.184h3.429v-.184L8.29 14.699c-.055-.07-.086-.156-.086-.245v-5.14l3.118 6.992h.617l2.633-6.327v4.94c0 .065-.026.127-.072.173l-1.031 1.031v.184H18v-.184l-.908-.908c-.046-.046-.072-.108-.072-.173V8.265c0-.065.026-.127.072-.173L18 7.184V7h-3.25l-2.41 5.775L9.801 7z"
                        fill="currentColor" fill-rule="evenodd"></path>
                </svg></a>
        </div>
    </div>
</div>