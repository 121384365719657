import { Injectable, NgProbeToken } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

declare var Web3: any;
declare var Web3HttpProvider: any;
var autoStakeAbi = require('./stake.json');
var flexibleStakeAbi = require('./flexiblestake.json');
var lpStakeAbi = require('./lpstake.json');
declare let window: any;

@Injectable({
    providedIn: 'root'
})
export class StakeService {
    headers: HttpHeaders;

    web3: any;
    FLEXIBLE = "0x0b42CeecD3e4c3CdBBb09b8A9F68b893bb1385Bf"; //flexiblestake
    LOCKED = "0x0e34F612C940aab4cC6122a5161a9b44070520f3"; //stake
    LP = "0xe036a87489276A0757B2BB7281d272D6F4be666e"; //lpstake
    DECIMAL = 18;

    constructor(private http: HttpClient) {

    }

    getDexscreeener() {
        this.headers = new HttpHeaders().set('Content-Type', 'application/json');
        var path = "https://api.dexscreener.com/latest/dex/tokens/0xDc63269eA166b70d4780b3A11F5C825C2b761B01";
        const url = path;
        console.log(url);
        return this.http
            .get(url, { headers: this.headers })
            .pipe(map(response => response))
    }

    getWeb3Service() {
        if (this.web3 == null) {
            window['web3'] = new Web3(new Web3HttpProvider('https://cloudflare-eth.com/'));
            // window['web3'] = new Web3(new Web3HttpProvider('https://data-seed-prebsc-2-s3.binance.org:8545'));
            this.web3 = window['web3']
        }
        return this.web3;
    }

    getAutoStakeAbi() {
        return autoStakeAbi;
    }

    getLPStakeAbi() {
        return lpStakeAbi;
    }

    getFlexibleStakeAbi() {
        return flexibleStakeAbi;
    }
}