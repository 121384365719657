import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EXTERNAL_LINKS } from '@shared/constants/common/links';
import { FOOTER_LINKS } from '@core/rubic-footer/models/footer-links';
import { ThemeService } from '@core/services/theme/theme.service';
import { StakeService } from '../services/stake/stake.service';
import { HttpClient } from '@angular/common/http';

declare var numeral: any;

@Component({
  selector: 'paw-footer',
  templateUrl: './paw-footer.component.html',
  styleUrls: ['./paw-footer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PawFooterComponent implements OnInit {
  public readonly footerLinks = FOOTER_LINKS;

  public readonly year = new Date().getFullYear();

  public readonly theme$ = this.themeService.theme$;

  web3: any;
  priceUsd: any;
  totalStakeBalance: any;
  tvl: any;

  /**
   * Returns landing domain address.
   */
  public get landingDomain(): string {
    return EXTERNAL_LINKS.LANDING;
  }

  constructor(private readonly themeService: ThemeService, private stakeService: StakeService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.stakeService.getDexscreeener().subscribe((result: any) => {
      console.log(result);
      this.priceUsd = result['pairs'][0].priceUsd;
      console.log(this.priceUsd);
      this.getInfoOfStake();
      this.changeDetectorRef.detectChanges();
    });
  }

  async getInfoOfStake() {
    this.web3 = this.stakeService.getWeb3Service();
    let stakeContract = await new this.web3.eth.Contract(this.stakeService.getFlexibleStakeAbi(), this.stakeService.FLEXIBLE);
    this.totalStakeBalance = await stakeContract.methods.TotalDeposits().call();
    this.totalStakeBalance = parseFloat(this.web3.utils.fromWei(this.totalStakeBalance))

    let stakeContractTwo = await new this.web3.eth.Contract(this.stakeService.getAutoStakeAbi(), this.stakeService.LOCKED);
    let auxOne = await stakeContractTwo.methods.totalStaked().call();
    this.totalStakeBalance += parseFloat(this.web3.utils.fromWei(auxOne))

    let stakeContractThree = await new this.web3.eth.Contract(this.stakeService.getLPStakeAbi(), this.stakeService.LP);
    let stakeBalance = await stakeContractThree.methods.TotalDeposits().call();
    let correctStakeBalance = await stakeContractThree.methods.CalculatePAWequivalent(stakeBalance).call()
    this.totalStakeBalance += parseFloat(this.web3.utils.fromWei(correctStakeBalance))

    this.tvl = this.totalStakeBalance * parseFloat(this.priceUsd)
    this.tvl = numeral(this.tvl).format('0,0')
    this.changeDetectorRef.detectChanges();
    }
  }
